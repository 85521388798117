import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Icon,
    Link,
    Stack,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import DataTable from "react-data-table-component";
  import { useNavigate, useParams } from "react-router-dom";
  import CustomLoader from "../../components/CustomLoader";
  import errorHandler from "../../utils/errorHandler";
  import { getSrno } from "../../utils/helpers";
  import TeacherManagementService from "../../services/TeacherManagementService";
  
  export default function AllStudentList() {
    const navigate = useNavigate();
  
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    useEffect(() => {
        const fetchInstitutionList = async () => {
            try {
                setLoading(true);
                let res = await TeacherManagementService.allstudentLists({id});   
                setData(res?.data?.data)     
            } catch (error) {
                errorHandler(error);
            } finally {
                setLoading(false);
            }
        };
        fetchInstitutionList();
    }, []);
   
        const handleViewScores = (id) => {
            navigate(`/student-management/viewgrade/${id}`);
        };

    const columns = [
        {
            name: "Sr. No.",
            grow: 0,
            selector: (row ,index) => index+1,
        },
        {
            name: "Name",
            sortField: "name",
            sortable: true,
            selector: (row) => row?.name,
        },
        {
            name: "Email ID",
            sortField: "emailid",
            sortable: true,
            selector: (row) => row?.email,
        },
        {
            name: "Course Enrolled",
            sortField: "course",
            sortable: true,
            selector: (row) => row?.courses?.map(course => course.courseName).join(", "),
        },
        {
            name: "Scores",
            selector: (row) => (
                <span
                onClick={() => handleViewScores(row?._id)} // Trigger navigation on click
                style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                >
                View Scores
                </span>
            ),
        },
    ];
  
    return (
      <Card>
        <CardHeader
          titleTypographyProps={{ variant: "h4" }}
          title="All Student List"
        />
        <CardContent>
  
          <DataTable
            customStyles={{
              headCells: {
                style: {
                  fontSize: "15px",
                  fontWeight: "bold",
                },
              },
            }}
            columns={columns}
            data={data}
            progressPending={loading}
            progressComponent={<CustomLoader />}
          />
          <Stack
            spacing={2}
            mt={3}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              type="button"
              variant="outlined"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </Stack>
        </CardContent>
      </Card>
    );
  }
  