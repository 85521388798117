import { toast } from "react-toastify";
import { Box, CardContent, CardHeader, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Button, FormLabel, TextareaAutosize, Typography, Stack, Icon } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import FieldErrorMessage from "../../components/FieldErrorMessage";
import SaveButton from "../../components/SaveButton";
import CardLayout from "../../layouts/CardLayout";
import errorHandler from "../../utils/errorHandler";
import CoursesManagementList from "../../services/coursesManagementSerives";
import taskAndQuestionnair from "../../services/taskQuestionniarService";
import { CoursesManagementSchema } from "../../utils/Schema";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Loader } from "../../components/CustomLoader";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";

function EditAddCoursesManagement() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dropDownLanguage, setDropDownLanguage] = useState([]);
  const [dropDownInstitution, getDropdownInstitution] = useState([]);
  const [dropDownTaskList, getDropdownTaskList] = useState([]);
  const [droupDownTeacherList, getDroupDownTeacherList] = useState([]);
  const [removedTaskIds, setRemovedTaskIds] = useState([]);
  const [selectedInstitutionType, setSelectedInstitutionType] = useState("");
  const [selectedInstitutionDefault, setSelectedInstitutionDeault] = useState("");
  const [fatchAlldataForDefault, setFatchAlldataForDefault] = useState([]);
  const [dependentCourseEntityData, setDependentCourseEntity] = useState([]);
  const fetchTaskData = useCallback(async () => {
    if (id) {
      try {
        const response = await taskAndQuestionnair.getById({ id });
        const resData = response?.data?.data;       
        resetForm({
          values: {
            id: resData?._id,
            title: resData?.title || "",
            description: resData?.description || "",
            languageId: resData?.languageId?._id || "",
            status: resData?.status || "",
            slug: "Task"
          },
        });

      } catch (error) {
        errorHandler(error);
      } finally {
        // setLoading(false);
      }
    }
    
  }, [id]);

  const fetchDropDownLangauge = async () => {
    setLoading(true);
    try {
      let res = await taskAndQuestionnair.getDropdownLanguages({ slug: "Language" });
      setDropDownLanguage(res?.data?.data)
    } catch (error) {
      errorHandler(error);
    }

    try {
      let res = await taskAndQuestionnair.getDropdownLanguages({ slug: "InstitutionType" });
      getDropdownInstitution(res?.data?.data)
    } catch (error) {
      errorHandler(error);
    }
    
    try {
      
      let res = await CoursesManagementList.getteachers();
      if (res.status === 200) {
        setTimeout(() => {
          console.log(res?.data?.data ,"res?.data?.data")
          getDroupDownTeacherList(res?.data?.data)
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    fetchDropDownLangauge();
  }, []);

  useEffect(() => {
    fetchTaskData();
  }, [fetchTaskData]);

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      semesterId: {_id: "" } ,
      status: true,
      year: "",
      courseLevelId: { _id: "", title: "" },
      otherCourseLevelDescription: "",
      otherInstitutionDescription: "",
      teacherId: {
        _id:"",
        name: "",
        email: "",
      },
      institutionId: {_id: "" , title:"" } ,
      institutionType: "",
      languageId: "" ,
      tasks: [
        {
          taskId: "",
          cutOff: "",
          startDate: "",
          endDate: "",
          dueDate: "",
        },
      ],
    },

    validationSchema: CoursesManagementSchema,
    onSubmit: async (values, { setSubmitting }) => {

      try {
        let payload = {}
          if (id) {                       
              payload = { ...values,
              id:id,
              year: values?.year.toString() ,
              institutionId : values?.institutionId?._id,
              institutionType :values?.institutionType,
              languageId : values?.languageId,
              year: values?.year.toString(),  
              courseLevelId: values?.courseLevelId._id,
              semesterId: values?.semesterId?._id,
              otherCourseLevelDescription : values?.courseLevelId.title === "Other" ? values?.otherCourseLevelDescription : "",
              otherInstitutionDescription : values?.institutionId.title === "Other" ? values?.otherInstitutionDescription : "",

            };  
          }else {
            payload = {
              ...values,
              institutionId : values?.institutionId?._id,
              institutionType :values?.institutionType,
              languageId : values?.languageId,
              year: values?.year.toString(),
              courseLevelId: values?.courseLevelId._id,
              semesterId: values?.semesterId?._id,
              otherCourseLevelDescription : values?.courseLevelId.title === "Other" ? values?.otherCourseLevelDescription : "",
              otherInstitutionDescription : values?.institutionId.title === "Other" ? values?.otherInstitutionDescription : "",
            };
          }

          if (values?.institutionType === 'k12') {
            delete payload.institutionId;
            delete payload.semesterId;
          } 

          if (id) {
            
              payload.teacherId = values?.teacherId?._id;
          }
           delete payload.teacherId;

          if (!payload.teacherId) {
              payload.teacherId = values?.teacherId?._id;
          }

          if(values?.courseLevelId._id === "other"){
            delete payload.teacherId;
          }
          const res = id
          ? await CoursesManagementList.edit(payload)
          : await CoursesManagementList.add(payload);

        toast.success(res?.data?.message);
        navigate("/course-management");
      } catch (error) {
        errorHandler(error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { values, errors, touched,setFieldError, handleSubmit, getFieldProps, setFieldValue, resetForm ,handleBlur ,setFieldTouched} = formik;

  const handleLanguageChange = async (event) => {
    const selectedLanguageId = event.target.value;
    console.log(selectedLanguageId)
    setFieldValue("languageId", selectedLanguageId);
    try {
      // setLoading(true);
      const response = await CoursesManagementList.taskget({ languageId: selectedLanguageId });
      getDropdownTaskList(response?.data?.data)
    } catch (error) {
      errorHandler(error);
    } finally {
      // setLoading(false);
    }
  };
  
          
  const fetchTaskData1 = useCallback(async () => {
    if (values.languageId) {
      // setLoading(true);
      try {
        const response = await CoursesManagementList.taskget({ languageId: values?.languageId});
        getDropdownTaskList(response?.data?.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        // setLoading(false);
      }
    }
  }, [values.languageId]);


  const handleRemoveTask = (index, taskId) => {
    const newTasks = values.tasks.filter((_, i) => i !== index);
    setFieldValue("tasks", newTasks); 
    if (taskId) {
      setRemovedTaskIds((prev) => [...prev, taskId]);
    }
  };
  
  useEffect(() => {
    if (id) {
      const getInstitutionById = async () => {
        try {
          const response = await CoursesManagementList.getById({ id });
          const resData = response?.data?.data;
          setSelectedInstitutionType(resData?.institutionType)
          resetForm({
            values: {
              ...formik.values,
              name: resData?.name || "",
              description: resData?.description || "",  
              semesterId: { _id: resData?.semesterId?._id },
              teacherId: resData?.teacherId,
              status: resData?.status || "",
              year: resData?.year || "",
              courseLevelId: {
                _id:  resData?.courseLevelId?._id,
                title: resData?.courseLevelId?.title,
              },
              teacherId: {
                _id: resData?.teacherId?._id || "",
                name: resData?.teacherId?.name || "",
                email: resData?.teacherId?.email || "",
              },
              institutionId: {
                _id:  resData?.institutionId?._id || "",
                title:  resData?.institutionId?.title || "",
              },
              institutionType:  resData?.institutionType || "",
              languageId:  resData?.languageId?._id || "",
              otherCourseLevelDescription: resData?.otherCourseLevelDescription || "",
              otherInstitutionDescription: resData?.otherInstitutionDescription || "",
              tasks: resData?.tasks?.map((task) => ({
                taskId: task?.taskId?._id || "",
                cutOff: task?.cutOff || "",
                startDate: task?.startDate || "",
                endDate: task?.endDate || "",
                dueDate: task?.dueDate || "",
              })) || [{
                taskId: "",
                cutOff: "",
                startDate: "",
                endDate: "",
                dueDate: "",
              }],
            },
          });
        } catch (error) {
          errorHandler(error);
        }
      };
      getInstitutionById();
    }
  }, [id, resetForm]);


  useEffect(() => {
    values?.tasks?.forEach((task, index) => {
      const isValidTask = dropDownTaskList.some(item => item._id === task.taskId);
      if (!isValidTask) {
        setFieldValue(`tasks[${index}].taskId`, null);
      }
    });
  }, [dropDownTaskList]);
  useEffect(() => {
    if (values?.languageId) {
      fetchTaskData1();
    }
  }, [fetchTaskData1]);

  const handleInstitutionTypeChange = (e) => {
    const selectedType = e.target.value;   
    setSelectedInstitutionType(selectedType);
    setFieldValue("institutionType", selectedType);
    setFieldTouched("institutionType", false);

    if(selectedInstitutionDefault=== selectedType){
      setFieldValue("courseLevelId._id", fatchAlldataForDefault?.courseLevelId?._id );
    }else{
      setFieldValue("courseLevelId._id", "");
    }
  };


  useEffect(() => {
    const fetchCourseCreateData = async () => {
      const payload = {
        slug: selectedInstitutionType,
      };
  
      try {
        let res = await CoursesManagementList.getAllCourseEntityCreateCourse(payload);
        setDependentCourseEntity(res?.data?.data);
      } catch (error) {
        errorHandler(error);
        console.error("Error fetching course data:", error);
      }
    };
    if (selectedInstitutionType && selectedInstitutionType.trim() !== '') {
      fetchCourseCreateData();
    }
  }, [selectedInstitutionType]);
  
  const deleteData = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#44070B",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await CoursesManagementList.delete({ id });
          if (res?.status === 200) {
            navigate(-1)
            toast.success(res?.data?.message, { toastId: "success" });                     
          } else {
            toast.error(res?.data?.message, { toastId: "error" });
          }
        } catch (error) {
          console.error("Error deleting...", error);
          errorHandler(error);
        }
      }
    });
  };
  
  return (
    <Box>
     
      <Helmet>
        <title>{id ? "Edit" : "Add"} Course</title>
      </Helmet>
        <CardLayout>
          <CardHeader title={`${id ? "Edit" : "Add"} Course`} />
          <CardContent>
            <FormikProvider value={formik}>
              {loading?  <Loader /> :(
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={3}>

                    <Grid item xs={6}>
                    <FormControl fullWidth error={Boolean(touched?.languageId && errors?.languageId)}>
                        <InputLabel id="language-select-label">Language</InputLabel>
                        <Select
                          labelId="language-select-label"
                          id="language-select"
                          label="Language"
                          value={values?.languageId || ""} 
                          onChange={handleLanguageChange}
                          disabled={id}
                        >
                          {dropDownLanguage?.map((item) => (
                            <MenuItem key={item?._id} value={item?._id}>
                              {item?.title}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>{touched.languageId && errors.languageId}</FormHelperText>
                      </FormControl>                  
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl fullWidth error={Boolean(touched.institutionType && errors.institutionType)}>
                        <InputLabel id="institution-type">Institution Type</InputLabel>
                        <Select
                          labelId="institution-type"
                          id="institution-type"
                          label="Institution Type"
                          value={formik?.values?.institutionType || ""}
                          onChange={handleInstitutionTypeChange}
                          onBlur={handleBlur}
                        >
                          {dropDownInstitution?.map((item) => (
                            <MenuItem key={item?._id} value={item?.title}>
                              {item?.subSlug}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>{touched.institutionType && errors.institutionType}</FormHelperText>
                      </FormControl>
                    </Grid>
                    {console.log(values?.institutionType)}
                    {selectedInstitutionType === "k12" && (
                      <>
                        <Grid item xs={6}>
                          <FormControl
                            fullWidth
                            error={Boolean(touched.year && errors.year)}
                          >
                            <InputLabel id="year-label">Year</InputLabel>
                            <Select
                              labelId="year-label"
                              id="year-label"
                              label="Year"
                              {...getFieldProps("year")}
                            >
                              {dependentCourseEntityData?.Year?.map((item) => (
                                <MenuItem key={item?.value} value={item?.value}>
                                  {item?.label}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>
                              {touched.year && errors.year}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl
                            fullWidth
                            error={Boolean(touched.courseLevelId?._id && errors.courseLevelId?._id)}>
                            <InputLabel id="courselevel-label">Course Level</InputLabel>
                            <Select
                              labelId="courselevel-label"
                              id="courselevel-label"
                              label="Course Level"value={values?.courseLevelId?._id || ""}
                              {...getFieldProps("courseLevelId._id")}
                            >
                              {dependentCourseEntityData?.Courselevel?.map((item) => (
                                <MenuItem key={item?.value} value={item?.value}>
                                  {item?.label}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>
                              {touched.courseLevelId?._id && errors.courseLevelId?._id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </>
                    )}

                    {selectedInstitutionType === "postsecondary" && (
                      <>
                        <Grid item xs={6}>
                          <FormControl
                            fullWidth
                            error={Boolean(touched.year && errors.year)}
                          >
                            <InputLabel id="year-label">Year</InputLabel>
                            <Select
                              labelId="year-label"
                              id="year-label"
                              label="Year"
                              {...getFieldProps("year")}
                            >
                              {dependentCourseEntityData?.Year?.map((item) => (
                                <MenuItem key={item?.value} value={item?.value}>
                                  {item?.label}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>
                              {touched.year && errors.year}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      
                        <Grid item xs={6}>
                          <FormControl
                            fullWidth
                            error={Boolean(touched.courseLevelId?._id && errors.courseLevelId?._id)}
                          >
                            <InputLabel id="courselevel-label">Course Level</InputLabel>
                            <Select
                              labelId="courselevel-label"
                              id="courselevel-label"
                              label="Course Level"
                              value={formik?.values?.courseLevelId?._id || ""} 
                              onChange={(e) => {
                                const selectedId = e.target.value;
                                const selectedTitle = dependentCourseEntityData?.Courselevel?.find(option => option.value === selectedId)?.label || "";                                                          
                                formik.setFieldValue("courseLevelId._id", selectedId);
                                formik.setFieldValue("courseLevelId.title", selectedTitle);                                                     
                              }}
                            >

                              {dependentCourseEntityData?.Courselevel?.map((item) => (
                                <MenuItem key={item?.value} value={item?.value}>
                                  {item?.label}
                                </MenuItem>
                              ))}                            
                            </Select>
                            <FormHelperText>{touched.courseLevelId?._id && errors.courseLevelId?._id}</FormHelperText>
                          </FormControl>
                        </Grid>
                        
                        <Grid item xs={6}>
                          <FormControl
                            fullWidth
                            error={Boolean(touched?.semesterId && errors?.semesterId)}
                          >
                            <InputLabel id="semester-label">Semester</InputLabel>
                            <Select
                              labelId="semester-label"
                              id="semester-label"
                              label="Semester"
                              {...getFieldProps("semesterId._id")}
                            >
                              {dependentCourseEntityData?.Semester?.map((item) => (
                                <MenuItem key={item?.value} value={item?.value}>
                                  {item?.label}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>
                              {touched?.semesterId && errors?.semesterId}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      

                          <Grid item xs={6}>
                            <FormControl
                              fullWidth
                              error={Boolean(
                                touched.institutionId && errors.institutionId
                              )}
                            >
                              <InputLabel id="institution-label">Institution</InputLabel>
                              <Select
                                labelId="institution-label"
                                id="institution"
                                label="Institution"
                                value={formik?.values.institutionId?._id || ""}
                                onChange={(e) => {
                                  const institution = e.target.value;
                                  const selectedTitle = dependentCourseEntityData?.Institution?.find(option => option.value === institution)?.label || "";
                                  console.log(selectedTitle ,"selectedTitle")
                                  formik.setFieldValue("institutionId._id", institution);
                                  formik.setFieldValue("institutionId.title", selectedTitle);                                                  
                                }}
                              >
                                
                                {dependentCourseEntityData?.Institution?.map((item) => (
                                  <MenuItem key={item?.value} value={item?.value}>
                                    {item?.label}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>{touched.institutionId && errors.institutionId}</FormHelperText>
                            </FormControl>
                          </Grid>

                        {formik?.values?.courseLevelId?.title ==="Other" && (
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              id="otherCourseLevelDescription"
                              label="Other Course Level Description"
                              {...getFieldProps("otherCourseLevelDescription")}
                              error={Boolean(touched.otherCourseLevelDescription && errors.otherCourseLevelDescription)}
                              helperText={touched.otherCourseLevelDescription && errors.otherCourseLevelDescription}
                            />
                          </Grid>
                        )}

                        {formik?.values?.institutionId?.title ==="Other" && (
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              id="otherInstitutionDescription"
                              label="Other Institution Description"
                              {...getFieldProps("otherInstitutionDescription")}
                              error={Boolean(touched.otherInstitutionDescription && errors.otherInstitutionDescription)}
                              helperText={touched.otherInstitutionDescription && errors.otherInstitutionDescription}
                            />
                          </Grid>
                        )}
                        </>
                    )}

                    <Grid item xs={12} md={12}>
                      <TextField
                        fullWidth
                        id="name"
                        label="Course Name"
                        {...getFieldProps("name")}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <TextField
                        multiline
                        rows={10}
                        maxRows={10}
                        fullWidth
                        label="Description"
                        {...getFieldProps("description")}
                        error={Boolean(touched.description && errors.description)}
                        helperText={touched.description && errors.description}
                      />
                    </Grid>

                    <CardHeader title={`Choose Tasks`} />
                    <Grid item xs={12}>
                      {errors?.tasks && typeof errors?.tasks === 'string' && (
                        <Typography color="error">{errors?.tasks}</Typography>
                      )}
                        {
                          values?.tasks?.length === 0 && (
                            <>
                              <Button
                              type="button"
                              variant="contained"
                              onClick={() =>
                                  setFieldValue("tasks", [
                                  ...values.tasks,
                                  {
                                    startDate: "",
                                      cutOff: "",
                                      startDate: "",
                                      endDate: "",
                                      dueDate: "",
                                  },
                                  ])
                              }
                              >
                              Add Task
                              </Button>
                            </>
                          )
                        }

                        {values?.tasks?.map((task, index) => (
                          <div key={index}>
                            {index >=1 ?  <br/>:null}
                              <FormLabel>{`Task ${index + 1}`}</FormLabel>
                              <br /><br />
                              <Grid container spacing={2}>

                              <Grid item xs={6}>
                                <FormControl fullWidth error={Boolean(touched.tasks?.[index]?.taskId && errors.tasks?.[index]?.taskId)}>
                                  <InputLabel id={`task-${index}-select-label`}>{`Task ${index + 1} Name`}</InputLabel>
                                  <Select
                                    id={`task-${index}-select`}
                                    label={`Task ${index + 1} Name`}
                                    name={`tasks[${index}].taskId`}
                                    value={task?.taskId || ''}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      const isDuplicate = values.tasks.some((t, i) => t.taskId === value && i !== index);
                                      if (isDuplicate) {
                                        toast.error(`Alert: This task is already in use within this course. Choose another task to proceed.`);
                                        return;
                                      }
                                      setFieldValue(`tasks[${index}].taskId`, value);
                                      const selectedTaskId = value;
                                      setFieldValue(`tasks[${index}].taskId`, selectedTaskId);
                                    }}                                
                                    onBlur={handleBlur}
                                  >
                                    {dropDownTaskList?.map((item) => (
                                      <MenuItem key={item?._id} value={item?._id}>
                                        {item?.title}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                <FormHelperText  className='error-custom'>{touched.tasks?.[index]?.taskId && errors.tasks?.[index]?.taskId}</FormHelperText>
                              </Grid>

                              <Grid item xs={6}>
                                <FormControl fullWidth error={Boolean(touched.tasks?.[index]?.cutOff && errors.tasks?.[index]?.cutOff)}>
                                <InputLabel id={`cutOff-${index}-select-label`}>{`Task ${index + 1} Cut Off`}</InputLabel>
                                  <Select
                                    id={`cutOff-${index}-select`}
                                    label={`Task ${index + 1} Cut Off`}
                                    name={`tasks[${index}].cutOff`}
                                    value={task?.cutOff}
                                    onChange={e => setFieldValue(`tasks[${index}].cutOff`, e.target.value)}
                                    onBlur={handleBlur}
                                  >
                                    <MenuItem value={"70"}>70 %</MenuItem>
                                    <MenuItem value={"75"}>75 %</MenuItem>
                                    <MenuItem value={"80"}>80 %</MenuItem>
                                    <MenuItem value={"85"}>85 %</MenuItem>
                                    <MenuItem value={"90"}>90 %</MenuItem>
                                    <MenuItem value={"95"}>95 %</MenuItem>
                                    <MenuItem value={"100"}>100 %</MenuItem>
                                  </Select>

                                </FormControl>
                                <FormHelperText  className='error-custom'>{touched.tasks?.[index]?.cutOff && errors.tasks?.[index]?.cutOff}</FormHelperText>
                              </Grid>

                              <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <MobileDatePicker
                                    label={`Task ${index + 1} Start Date`}
                                    name={`tasks[${index}].startDate`}
                                    value={task?.startDate ? new Date(task.startDate) : null}
                                    className='datePicker-custom'
                                    minDate={new Date()}
                                    onBlur={handleBlur}
                                    onChange={(newValue) => {
                                      if (newValue) {
                                        const newTasks = [...values.tasks];
                                        newTasks[index].startDate = newValue;
                                        setFieldValue("tasks", newTasks);
                                      }
                                    }}
                                  />
                                </LocalizationProvider>
                                {errors.tasks?.[index]?.startDate && touched.tasks?.[index]?.startDate && (
                                  <FormHelperText>{touched.tasks?.[index]?.startDate && errors.tasks?.[index]?.startDate}</FormHelperText>
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <MobileDatePicker
                                    label={`Task ${index + 1} Due Date`}
                                    value={task?.dueDate ? new Date(task.dueDate) : null}
                                    className='datePicker-custom'
                                    onChange={(newValue) => {
                                      if (newValue) {
                                        const newTasks = [...values.tasks];
                                        const dueDateWithTime = new Date(newValue);
                                        dueDateWithTime.setHours(23, 59, 59);
                                        newTasks[index].dueDate = dueDateWithTime; 
                                        setFieldValue("tasks", newTasks);
                                      }
                                    }}
                                    minDate={new Date()}
                                  />
                                </LocalizationProvider>
                                {errors.tasks?.[index]?.dueDate && touched.tasks?.[index]?.dueDate && (
                                  <FormHelperText>{touched.tasks?.[index]?.dueDate && errors.tasks?.[index]?.dueDate}</FormHelperText>
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <MobileDatePicker
                                    label={`Task ${index + 1} End Date`}
                                    name={`tasks[${index}].endDate`}
                                    value={task?.endDate ? new Date(task?.endDate) : null}
                                    className='datePicker-custom'
                                    onChange={(newValue) => {
                                      if (newValue) {
                                        const newTasks = [...values?.tasks];
                                        const endDateWithTime = new Date(newValue);
                                        endDateWithTime.setHours(23, 59, 59);
                                        newTasks[index].endDate = endDateWithTime;
                                        setFieldValue("tasks", newTasks);
                                      }
                                    }}
                                    minDate={new Date()}
                                    
                                  />
                                </LocalizationProvider>
                                {errors.tasks?.[index]?.endDate && touched.tasks?.[index]?.endDate && (
                                  <FormHelperText>{touched.tasks?.[index]?.endDate && errors.tasks?.[index]?.endDate}</FormHelperText>
                                )}
                              </Grid>
                           
                            <Grid item xs={12}>
                            { values?.tasks?.length > 1  && (
                                <Button
                                    type="button"
                                    variant="outlined"
                                    className='button-outlined'
                                    onClick={() => {
                                      const deleteId = values?.tasks[index]
                                      const taskName = dropDownTaskList.find(taskName => taskName?._id === deleteId?.taskId);
                                      if(id && taskName){
                                        Swal.fire({
                                          title: "Are you sure you want to delete this task?",
                                          text: `Deleting it will permanently remove all records of students' scores and progress related to this Task ${taskName?.title} , and this information cannot be recovered.`,
                                          icon: "warning",
                                          showCancelButton: true,
                                          confirmButtonColor: "#44070B",
                                          confirmButtonText: "Yes, delete it!",
                                        }).then(async (result) => {
                                          if (result.isConfirmed) {
                                            const newTasks = values?.tasks?.filter((_, i) => i !== index);
                                             setFieldValue("tasks", newTasks);
                                          }
                                        });
                                      }
                                      else{                                     
                                        const newTasks = values?.tasks?.filter((_, i) => i !== index);
                                        setFieldValue("tasks", newTasks);
                                    }}}
                                >
                                Remove
                                </Button>
                            )}      
                              
                                {index === values?.tasks?.length - 1 && (
                                <Button
                                  type="button"
                                  variant="contained"
                                  sx={{ ml: 2, mr: 2 }}
                                  onClick={() =>
                                  setFieldValue("tasks", [
                                    ...values.tasks,
                                      {
                                        taskId: "",
                                        cutOff: "",
                                        startDate: "",
                                        endDate: "",
                                        dueDate: "",
                                      },
                                    ])
                                  }
                                >
                                    Add Task
                                </Button>
                                )}
                            </Grid>
                          </Grid>
                          </div>
                        ))}
                    </Grid>

                    <Grid item xs={12}></Grid>
                    <CardHeader title={`Select Teacher`} />
      
                    <Grid item xs={12} md={12}>
                      <FormControl fullWidth error={Boolean(touched.teacherId?._id && errors.teacherId?._id)}>
                        <InputLabel id="CreatorEmail-type">Teacher</InputLabel>
                        <Select
                          labelId="CreatorEmail-type"
                          id="CreatorEmail"
                          label="Teacher Email"
                          value={values?.teacherId?._id} 
                          onChange={(e) => {
                            const selectedTeacherId = e.target.value;
                            const selectedTeacher = droupDownTeacherList.find(teacher => teacher._id === selectedTeacherId);
                            
                            setFieldValue("teacherId._id", selectedTeacherId);
                            setFieldValue("teacherId.name", selectedTeacher?.name || "");
                          }}
                          disabled={id}
                        >
                          {droupDownTeacherList?.map((item) => (
                            <MenuItem key={item?._id} value={item?._id}>
                               {item?.name} , { item?.email}
                                
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>{touched.teacherId?._id && errors.teacherId?._id}</FormHelperText>
                      </FormControl>
                    </Grid>
                    
                    <Grid item xs={12}>
                      <FormControl fullWidth error={Boolean(touched?.status && errors?.status)}>
                        <InputLabel id="status-select-label">Status</InputLabel>
                        <Select
                          labelId="status-select-label"
                          id="status-select"
                          value={values.status}
                          label="Status"
                          onChange={(e) => setFieldValue("status", e.target.value)}
                        >
                          <MenuItem value={true}>Active</MenuItem>
                          <MenuItem value={false}>Inactive</MenuItem>
                        </Select>
                      </FormControl>
                      <FieldErrorMessage formik={formik} name="status" />
                    </Grid>                

                  </Grid>

                  {/* {id ? */}
                   <SaveButton loading={loading} id={id} type={id? 'delete' :''} deleteData={deleteData} /> 
                    {/* :''} */}
                </Form>
              )}
            </FormikProvider>
          </CardContent>
        </CardLayout>

    </Box>
  );
}

export default EditAddCoursesManagement;