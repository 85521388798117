import { Box, Button, Card, CardContent, CardHeader, IconButton, MenuItem, Select, Tooltip} from "@mui/material";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../../components/CustomLoader";
import errorHandler from "../../utils/errorHandler";
import { capitalize, getSrno } from "../../utils/helpers";
import SearchInput from "../../components/SearchInput";
import { toast } from "react-toastify";
import { EditOutlined } from "@mui/icons-material";
import CoursesManagementList from "../../services/coursesManagementSerives"
import VisibilityIcon from "@mui/icons-material/Visibility";
import { fDate } from "../../utils/formatTime";

export default function CoursesManagement() {
  const navigate = useNavigate();

  const [paginData, setPaginData] = useState({
    totalCount: 0,
    data: [],
  });
  const [loading, setLoading] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState("");


  const [payload, setPayload] = useState({
    per_page: 10,
    current_page: 1,
    search: "",
    sortObject: { createdAt: -1 },
  });

  const fetchInstitutionList = async () => {
    try {
      setLoading(true);
      let res = await CoursesManagementList.getAll({
        ...payload,
        teacherId: selectedTeacher,
      });
      setPaginData({
        data: res?.data?.data, 
        totalCount: res?.data?.totalCount,
      });
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInstitutionList();
  }, [payload]);

  const fetchTeachers = async () => {
    try {
      let response = await CoursesManagementList.getteachers();
      console.log(response?.data?.data)
      setTeachers(response?.data?.data || []);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    fetchTeachers();
  }, [selectedTeacher]);
  
  const deleteData = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#44070B",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await CoursesManagementList.delete({ id });
          if (res?.status === 200) {
            toast.success(res?.data?.message, { toastId: "success" });
            setPaginData((prev) => ({
              ...prev,
              data: prev.data.filter((item) => item._id !== id),
              totalCount: prev.totalCount - 1,
            }));
            
          } else {
            toast.error(res?.data?.message, { toastId: "error" });
          }
        } catch (error) {
          console.error("Error deleting...", error);
          errorHandler(error);
        }
      }
    });
  };
 
  const columns = [
    {
      name: "Sr. No.",
      cell: (row, index) =>
        getSrno(payload.current_page, payload.per_page, index),
      grow: 0.6,
    },
    {
      name: "Course Name",
      sortField: "courseName",
      sortable: true,
      selector: (row) => capitalize(row?.name),
    },
    {
      name: "Institution Type",
      sortField: "institutionType",
      sortable: true,
      selector: (row) => capitalize(row?.institutionType),
    },
    {
      name: "Teacher",
      sortField: "Teacher",
      sortable: true,
      selector: (row) =>capitalize(row?.teacher?.name),
    },
    {
      name: "Course Level",
      sortField: "institutcourseLevelionType",
      sortable: true,
      selector: (row) => capitalize(row?.courseLevel?.title),
    },
    {
      name: "Year",
      sortField: "year",
      sortable: true,
      selector: (row) => row?.year,
    },
    {
      name: "Created",
      sortField: "CreatedAt",
      sortable: true,
      selector: (row) => fDate(row?.createdAt),
    },
    {
      name: "Language",
      sortField: "language",
      sortable: true,
      selector: (row) => capitalize(row?.courseLanguage?.title),
    },
    {
      name: "Action",
      right: true,
      cell: (row) => (
        <>
          <Tooltip title="Edit" placement="left">
            <IconButton
              color="primary"
              onClick={() => {
                navigate(`/course-management/edit/${row._id}`);
              }}
            >
              <EditOutlined />
            </IconButton>
          </Tooltip>

          <Tooltip title="View" placement="top" leaveDelay={50}>
            <IconButton
              color="primary"
              onClick={() => {
                navigate(`/course-management/view/${row._id}`);
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete" placement="right">
            <IconButton color="primary" onClick={() => deleteData(row?._id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];
  const handleSort = (column, sortDirection) => {
    const srt = {
      [column.sortField]: sortDirection === "asc" ? -1 : 1
    };
    setPayload((prev) => ({
      ...prev,
      sortObject: srt
    }));
  };
  return (
    <Card>
      <CardHeader
        titleTypographyProps={{ variant: "h4" }}
        title="Course Management"
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >

        <Select
          value={selectedTeacher}
          onChange={(e) => {
            setSelectedTeacher(e.target.value);
            setPayload((prev) => ({ ...prev, current_page: 1 })); // Reset to page 1 on filter change
          }}
          displayEmpty
          variant="outlined"
          sx={{ ml: 1, mr: 2 }}
        >
          <MenuItem value="">
            <em>All Teachers</em>
          </MenuItem>
          {teachers.map((teacher , index) => (
            <MenuItem key={index} value={teacher?._id}>
              {teacher?.name} , {teacher?.email}
            </MenuItem>
          ))}
        </Select>

        <SearchInput data={payload} setSearch={setPayload} />
        <Button
          size="large"
          type="submit"
          variant="contained"
          sx={{ ml: 3, mr: 3 }}
          onClick={() => navigate("/course-management/add")}
        >
          Add
        </Button>
      </Box>
      <CardContent>
        <DataTable
          customStyles={{
            headCells: {
              style: {
                fontSize: "15px",
                fontWeight: "bold",
              },
            },
          }}
          columns={columns}
          data={paginData?.data}
          pagination
          paginationServer
          paginationTotalRows={paginData?.totalCount}
          onChangePage={(page) =>
            setPayload((prev) => ({ ...prev, current_page: page }))
          }
          paginationPerPage={payload.per_page}
          paginationRowsPerPageOptions={[5, 10, 20, 50]}
          onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
            setPayload((prev) => ({
              ...prev,
              per_page: currentRowsPerPage,
              current_page: currentPage,
            }));
          }}
          progressPending={loading}
          onSort={handleSort}
          progressComponent={<CustomLoader />}
        />
      </CardContent>
    </Card>
  );
}
